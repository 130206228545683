<template>
  <div class="text-dark">
    <b-container fluid>
      <div v-if="
        detailSubscription &&
        detailSubscription.payment_status === 'pending' &&
        detailSubscription.payment_method === 'mandiri_va'
      ">
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Transaksi </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-warning p-1 rounded-pill fw-bold-700">
                  {{
                    detailSubscription.payment_status === "pending"
                      ? "Menunggu Pembayaran"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Metode Pembayaran </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.payment_method ===
                    "mandiri_va"
                    ? "MANDIRI VA - "
                    : ""
                }}
                {{ detailSubscription.va_account_number }}
                <feather-icon icon="CopyIcon" class="text-primary cursor-pointer" size="16" @click="
                  copyToClipboard(
                    detailSubscription.va_account_number
                  )
                  " />
                <div v-if="copied" class="badge badge-light-success">
                  Berhasil disalin!
                </div>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Waktu Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.created_at }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Bayar Sebelum </b-col>
              <b-col class="text-right text-danger">
                {{ detailSubscription.payment_expired_at }}
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Cara Bayar </b-col>
            </b-row>
            <div class="card p-1" v-b-toggle.collapse-1 variant="primary">
              <div class="d-flex align-items-center">
                <b-img :src="require('/src/assets/images/logo/mandiri.png')" alt="mandiri" style="width: 4rem" />
                <span class="ml-1"> Bank Mandiri - Virtual Account </span>
                <span class="ml-auto">
                  <feather-icon icon="ChevronDownIcon" size="16" />
                </span>
              </div>
              <b-collapse id="collapse-1">
                <hr />
                <div class="size16 fw-bold-900 ml-1">Melalui ATM</div>
                <ol>
                  <li>Silahkan pilih menu “Bayar/Beli”</li>
                  <li>
                    Selanjutnya akan muncul tipe pembayaran seperti Multi
                    Payment dan Bill Payment (PLN, PDAM, dll), untuk pembayaran
                    VA pilih “Multi Payment”
                  </li>
                  <li>
                    Silahkan masukkan kode perusahaan, contoh: “88908” (Xendit),
                    lalu pilih “Benar”
                  </li>
                  <li>
                    Masukkan nomor Virtual Account Anda (contoh:
                    8890802001287578) dan klik “Benar”
                  </li>
                  <li>
                    Isikan nominal yang akan dibayarkan, kemudian tekan “Benar”
                  </li>
                  <li>
                    Jangan lupa untuk memeriksa informasi yang tertera pada
                    layar. Pastikan semua informasi dan total tagihan yang
                    ditampilkan sudah benar. Jika benar, tekan angka 1 dan pilih
                    “Ya”
                  </li>
                  <li>
                    Periksa layar konfirmasi dan pilih “Ya” untuk melakukan
                    pembayaran
                  </li>
                  <li>
                    Bukti pembayaran dalam bentuk struk agar disimpan sebagai
                    bukti pembayaran yang sah dari Bank Mandiri
                  </li>
                  <li>Transaksi Anda telah selesai</li>
                </ol>
                <div class="size16 fw-bold-900 ml-1">
                  Melalui Aplikasi Livin' by Mandiri
                </div>
                <ol>
                  <li>Silahkan login ke mobile banking Anda.</li>
                  <li>Klik “Icon Menu” di sebelah kiri atas.</li>
                  <li>Pilih menu “Pembayaran”</li>
                  <li>Lanjut buat “Pembayaran Baru”</li>
                  <li>Pilih “Multi Payment”</li>
                  <li>
                    Klik “Penyedia Jasa” atau “Service Provider”, kemudian pilih
                    kode perusahaan, contoh: “Xendit 88908”
                  </li>
                  <li>Pilih “No. Virtual”</li>
                  <li>
                    Masukkan nomor Virtual Account Anda dengan kode perusahaan
                    (contoh 8890802001287578), kemudian pilih “Tambah Sebagai
                    Nomor Baru”
                  </li>
                  <li>Masukkan nominal lalu pilih “Konfirmasi” dan “lanjut”</li>
                  <li>
                    Selanjutnya akan muncul tampilan konfirmasi pembayaran.
                    Pastikan semua informasi dan total tagihan sudah benar. Jika
                    sudah benar, lalu scroll ke bawah dan pilih “Konfirmasi”
                  </li>
                  <li>Masukkan PIN Anda dan pilih “OK”</li>
                  <li>
                    Sampai pada tahap ini, berarti transaksi dengan menggunakan
                    VA telah berhasil dilakukan.
                  </li>
                </ol>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="
        detailSubscription &&
        detailSubscription.payment_status === 'success' &&
        detailSubscription.payment_method === 'mandiri_va'
      ">
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right" @click="print">
                <b-button class="bg-white border-8">
                  {{ detailSubscription.invoice_number }}
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-primary p-1 rounded-pill fw-bold-700">
                  {{
                    detailSubscription.payment_status === "success"
                      ? "Aktif"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="
        detailSubscription &&
        detailSubscription.payment_status === 'canceled' &&
        detailSubscription.payment_method === 'mandiri_va'
      ">
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="bg-danger p-1 rounded-pill fw-bold-700 text-white">
                  {{
                    detailSubscription.payment_status === "canceled"
                      ? "Dibatalkan"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="
        detailSubscription &&
        detailSubscription.payment_status === 'expired' &&
        detailSubscription.payment_method === 'mandiri_va'
      ">
        <b-row align-h="center">
          <b-col xl="5" lg="6" md="8" sm="9" xs="12">
            <b-row class="mb-2"> </b-row>
            <b-row class="mb-1">
              <b-col class="size24 fw-bold-800"> Detail Berlangganan </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> ID Transaksi </b-col>
              <b-col class="text-right">
                {{ detailSubscription.invoice_number }}
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col> Status </b-col>
              <b-col class="text-right">
                <span class="secondary-2 p-1 rounded-pill fw-bold-700 text-white">
                  {{
                    detailSubscription.payment_status === "expired"
                      ? "Kedaluwarsa"
                      : ""
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Paket Berlangganan </b-col>
              <b-col class="text-right">
                {{ detailSubscription.subscription_renewal.renewal_name }}
              </b-col>
            </b-row>
            <!-- <b-row class="mb-2" v-if="detailSubscription.subscription_renewal">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{
                  detailSubscription.subscription_renewal.sell_price
                    | formatAmount
                }}
              </b-col>
            </b-row> -->
            <b-row class="mb-2" v-if="detailSubscription.total_price">
              <b-col> Nominal </b-col>
              <b-col class="text-right">
                {{ detailSubscription.total_price | formatAmount }}
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col> Berlaku Hingga </b-col>
              <b-col class="text-right">
                {{ detailSubscription.payment_expired_at }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <InvoiceSubscription :detailSubscription="detailSubscription" class="d-none" />
  </div>
</template>

<script>
import {
  BModal,
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BCard,
  BButton,
  BFormCheckbox,
  BFormRadio,
  BCollapse,
  VBToggle,
} from "bootstrap-vue";

import ModalHeader from "@/components/ModalHeader.vue";
import InvoiceSubscription from "@/components/InvoiceSubscription.vue";

export default {
  components: {
    ModalHeader,
    BModal,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BCard,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BCollapse,
    InvoiceSubscription,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      copied: false,
    };
  },
  props: {
    detailSubscription: Object,
  },
  methods: {
    copyToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);

      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1500);
    },
    async print() {
      const { hostname, protocol, port } = window.location;

      const noLandscapeOptions = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: [
          `${protocol}//${hostname}${port !== "" ? `:${port}` : ""
          }/bootstrap.css`,
        ],
        timeout: 1000,
        autoClose: true,
        windowTitle: window.document.title,
      };

      await this.$htmlToPaper("contentPrintSubscription", noLandscapeOptions);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";

.secondary-2 {
  background-color: $secondary-2;
}
</style>
